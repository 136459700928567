<template>
	<section
		ref="questionnaire"
		class="section section-form"
	>
		<div class="content">
			<div
				id="applicationForm"
				class="form"
			>

<!--                <h3 class="title-section">-->
<!--					Заполните анкету-->
<!--				</h3>-->
				<div class="form__body">
<!--					<div class="field-simple">-->
<!--						<span class="field-simple__title">Даритель</span>-->
<!--						<input-->
<!--							v-model="name"-->
<!--							type="text"-->
<!--							class="field-simple__value"-->
<!--							:class="{'field-simple__value&#45;&#45;error': $v.name.$error}"-->
<!--							placeholder="Введите имя, фамилию"-->
<!--						>-->
<!--						<div-->
<!--							v-if="$v.name.$error"-->
<!--							class="field-simple__error"-->
<!--						>-->
<!--							{{-->
<!--								!$v.name.required-->
<!--									? "Это обязательное поле"-->
<!--									: !$v.name.maxLength-->
<!--										? "Недопустимое количество символов"-->
<!--										: "Ошибка поля"-->
<!--							}}-->
<!--						</div>-->
<!--					</div>-->
<!--					<div class="field-simple">-->
<!--						<span class="field-simple__title">Компания</span>-->
<!--						<input-->
<!--							v-model="company"-->
<!--							type="text"-->
<!--							class="field-simple__value"-->
<!--							:class="{'field-simple__value&#45;&#45;error': $v.company.$error}"-->
<!--							placeholder="Введите название компании"-->
<!--						>-->
<!--						<div-->
<!--							v-if="$v.company.$error"-->
<!--							class="field-simple__error"-->
<!--						>-->
<!--							{{-->
<!--								!$v.company.required-->
<!--									? "Это обязательное поле"-->
<!--									: !$v.company.maxLength-->
<!--										? "Недопустимое количество символов"-->
<!--										: "Ошибка поля"-->
<!--							}}-->
<!--						</div>-->
<!--					</div>-->
<!--					<div class="field-simple">-->
<!--						<span class="field-simple__title">E-mail</span>-->
<!--						<input-->
<!--							v-model="email"-->
<!--							type="email"-->
<!--							class="field-simple__value"-->
<!--							placeholder="Введите e-mail"-->
<!--							:class="{'field-simple__value&#45;&#45;error': $v.email.$error}"-->
<!--						>-->
<!--						<div-->
<!--							v-if="$v.email.$error"-->
<!--							class="field-simple__error"-->
<!--						>-->
<!--							{{-->
<!--								!$v.email.required-->
<!--									? "Это обязательное поле"-->
<!--									: !$v.email.maxLength-->
<!--										? "Недопустимое количество символов"-->
<!--										: "Ошибка поля"-->
<!--							}}-->
<!--						</div>-->
<!--						<div class="field-simple__notice title-mono">-->
<!--							на email вы получите отчет по оказанной вами помощи-->
<!--						</div>-->
<!--					</div>-->
<!--					<div class="field-simple">-->
<!--						<span class="field-simple__title">Телефон</span>-->
<!--						<input-->
<!--							v-model="phone"-->
<!--							v-mask="'+7(###)###-##-##'"-->
<!--							type="tel"-->
<!--							class="field-simple__value"-->
<!--							placeholder="Введите номер телефона"-->
<!--							:class="{'field-simple__value&#45;&#45;error': $v.phone.$error}"-->
<!--						>-->
<!--						<div-->
<!--							v-if="$v.phone.$error"-->
<!--							class="field-simple__error"-->
<!--						>-->
<!--							Это обязательное поле-->
<!--						</div>-->
<!--					</div>-->
					<label class="checkbox checkbox--error">
						<input
							v-model="isAgreePersonal"
							type="checkbox"
						>
						<span>Соглашаюсь <a href="https://ea5ec095-4ab2-4f77-811e-49e733f2acc8.selcdn.net/contest_service/user_files/375270/bf00d10d-ad5f-4c46-b52b-da2b812e5762/ofert.pdf" target="_blank">с условиями договора пожертвования</a></span>
						<span
							v-if="$v.isAgreePersonal.$error"
							class="checkbox__error"
						>Это обязательное поле</span>
					</label>
<!--					<label class="checkbox checkbox&#45;&#45;error">-->
<!--						<input-->
<!--							v-model="agreementView"-->
<!--							type="checkbox"-->
<!--						>-->
<!--						<span>Отправить как анонимный даритель</span>-->
<!--					</label>-->
				</div>
				<div class="form__footer">
<!--					<button-->
<!--						v-if="!isSended"-->
<!--						class="button button&#45;&#45;red"-->
<!--						@click.prevent="payment"-->
<!--					>-->
<!--						Продолжить-->
<!--					</button>-->
<!--					<div-->
<!--						v-else-->
<!--						class="button button&#45;&#45;red lg button-preloader"-->
<!--					>-->
<!--						Отправляется-->
<!--						<div-->
<!--							class="button-preloader-icons"-->
<!--							style="background: #A93015;"-->
<!--						>-->
<!--							<div class="button-preloader-icon" />-->
<!--							<div class="button-preloader-icon" />-->
<!--							<div class="button-preloader-icon" />-->
<!--						</div>-->
<!--					</div>-->
					<button
						class="button button--hollow"
						@click="payment"
					>
<!--						@click="modalOpen('show-qr')"-->
						Показать QR код для оплаты
					</button>
					<p class="title-mono">
						Осуществляя пожертвование с использованием qr-кода подтверждаю, что ознакомился с условиями программы и предоставляю согласие на обработку персональных данных
					</p>
				</div>
			</div>
		</div>
		<img
			src="../assets/img/christmas/design__lights-2.png"
			alt="Изображение"
			class="design-form design-form--1"
		>
		<img
			src="../assets/img/christmas/design__toy-3.png"
			alt="Изображение"
			class="design-form design-form--2"
		>
	</section>
</template>

<script>
// import { email, sameAs, required, maxLength } from 'vuelidate/lib/validators';
import {sameAs} from 'vuelidate/lib/validators';
// import { alphaSpace, Phone } from '@/assets/scripts/custom_validators';

export default {
    name: 'ApplicationForm',
    props: {
        isSended: {
            type: Boolean,
            default: false
        }
    },
    data: ()=> ({
        name: '',
        company: '',
        email: '',
        phone: '',
        isAgreePersonal: false,
        agreementView: false
    }),
    validations: {
        // name: { required, alphaSpace, maxLength: maxLength(200) },
        // company: { required, maxLength: maxLength(200) },
        // email: { required, email, maxLength: maxLength(254) },
        // phone: { required, Phone },
        isAgreePersonal: { sameAs: sameAs(() => true) },
    },
    methods: {
        payment() {
            this.$v.$touch();
            if (this.$v.$invalid ) {
                this.$scrollTo('#applicationForm');
            } else {
                // let applicationUser = {
                //     phone: this.phone,
                //     auto_deposit: false,
                //     name: this.name,
                //     company: this.company,
                //     email: this.email,
                //     is_public: !this.agreementView,
                // };
                // this.$emit('sendApplication', applicationUser);
                this.modalOpen('show-qr');
            }
        }
    }
};
</script>
