<template>
	<div class="page page-christmas">
		<main class="body">
            <Banner />
            <Persons />
            <Stories />
            <Steps />
            <Toys @getSumToy="setAmount" />
            <ApplicationForm
                :is-sended="isSending"
                @sendApplication="sendApplication"
            />
<!--            <Helpers />-->
            <Associate />
		</main>
		<Footer />

        <transition name="fade">
            <modal v-if="isActiveModal" />
        </transition>
	</div>
</template>

<script>
import CH from '@/api/charity';
import { maxValue, minLength, minValue, required } from 'vuelidate/lib/validators';
import { onlyNumber } from '@/assets/scripts/custom_validators';
import {mapGetters} from "vuex";

import Banner from '@/components/Banner';
import Persons from '@/components/Persons';
import Stories from '@/components/Stories';
import Steps from '@/components/Steps';
import Toys from '@/components/Toys';
import ApplicationForm from '@/components/ApplicationForm';
// import Helpers from '@/components/Helpers';
import Associate from '@/components/Associate';
import Footer from '@/components/Footer';
import Modal from '@/components/modal/Modal';

export default {
	name: 'App',
	data() {
		return {
			amount: 730,
            isSending: false
		};
	},
    validations: {
        amount: {
            required,
            onlyNumber,
            minLength: minLength(1),
            minValue: minValue(1),
            maxValue: maxValue(1000000)
        },
    },
    computed: {
    ...mapGetters('modal', {
            isActiveModal: 'isActive'
        }),
    },
    methods: {
        setAmount (sum) {
            this.amount = sum
        },
        sendApplication(data) {
            this.$v.amount.$touch()
            if (this.$v.amount.$invalid) {
                this.$scrollTo('#customSum')
            } else if (!this.$v.amount.$invalid) {
                this.isSending = true
                let result = { ...data, amount: this.amount};
                CH.donateToChildren(result)
                    .then(response => {
                        window.location.href = response.data
                        // this.modalOpen('donate-scs', response.data)
                        this.isSending = false
                    })
                    .catch(err => {
                        this.modalOpen('stdFail', err.response.data)
                        this.isSending = false
                    })

            }
        }
    },
    components: {
        Banner,
        Persons,
        Stories,
        Steps,
        Toys,
        ApplicationForm,
        // Helpers,
        Associate,
        Footer,
        Modal
    }
};
</script>

<style lang="less">
@import './assets/styles/compiled.less';
</style>
