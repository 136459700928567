<template>
	<section class="section section-persons">
		<div class="content">
			<div class="persons">
				<a
				/> 
				<h2 class="title-section">
					Каждый наступающий Новый год - прекрасная возможность для всех нас убедиться, что чудо точно произойдет: совершить его!
				</h2>
				<div
					v-if="children.length"
					class="persons__list"
				>
					<div
						v-for="item in children"
						:key="item.id"
						class="person"
					>
						<img
							:src="item.image"
							alt="Фото"
							class="person__avatar"
						>
						<div class="person__content">
							<h4 class="person__name">
								{{ item.name }}
							</h4>
							<p class="person__info">
								{{ item.age | toPlural('год','года','лет') }}, {{ item.city }} <br>
								Мечта: {{ item.dream }} <br>
								Предметы: {{ item.lessons }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Helpers',
    computed: {
        ...mapGetters('christmas', {children: 'children'})
    },
    created() {
        this.getChildren()
    },
    methods: {
        ...mapActions('christmas', {getChildren: 'getChildren'})
    }
};
</script>
